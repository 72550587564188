<template>
  <div>
    <!--    <test />-->

    <b-row>
      <b-col md="2">
        <b-form-group label="Поиск">
          <b-form-input
            v-model="params.search"
            type="search"
            @input="searchConstructor"
          />
        </b-form-group>
      </b-col>

      <b-col md="2">
        <BFormGroup label="Портфель:">
          <VSelect
            v-model="params.company"
            class="bg-white rounded"
            label="COMPANY_NAME"
            :reduce="p =>p.COMPANY_ID"
            :options="debtCompanyList.results"
            @input="filtered"
          />
        </BFormGroup>
      </b-col>

      <b-col md="2">
        <b-form-group label="Статус">
          <v-select
            v-model="status"
            multiple
            class="bg-white rounded"
            :options="status2"
            label="title"
            @input="filteredCall"
          />
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group label="Статус звонков">
          <v-select
            v-model="status_call"
            multiple
            class="bg-white rounded"
            :options="status_call2"
            label="title"
            @input="filteredCall"
          />
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group label="Тип звонка">
          <v-select
            v-model="params.is_robot"
            class="bg-white rounded"
            :options="is_robot"
            :reduce="p => p.value"
            label="title"
            @input="filtered"
          />
        </b-form-group>
      </b-col>

      <b-col md="2">
        <BFormGroup
          label="Оператор"
          class="w-100"
        >
          <VSelect
            v-model="params.user"
            class="bg-white rounded"
            label="full_name"
            :reduce="p => p.id"
            :options="users.results"
            @input="filtered"
          />
        </BFormGroup>
      </b-col>

      <b-col
        md="1"
      >
        <b-button
          variant="outline-success"
        >
          <div class="d-flex align-items-center">
            <feather-icon icon="ListIcon" />
            <span class="ml-50">{{ callHistory.count }}</span>
          </div>
        </b-button>
      </b-col>

      <b-col
        class="ml-auto text-right"
        md="2"
      >
        <BButton

          variant="success"
          :disabled="exportXls"
          @click="exportExcel"
        >
          <b-spinner
            v-if="exportXls"
            small
            class="mr-1"
            label="Small Spinner"
          />
          <feather-icon
            v-else
            icon="DownloadIcon"
          />
          Export.xls
        </BButton>
      </b-col>
    </b-row>

    <b-overlay
      :show="loader"
      rounded="sm"
    >
      <b-card class="mt-1">
        <b-table
          :fields="fields"
          :items="callHistory.results"
          show-empty
          hover
          responsive
        >
          <template #empty>
            <empty />
          </template>
          <template #cell(debts)="props">
            <div class="d-flex flex-column">
              <a
                v-for="(item, index) in props.item.debts"
                :key="index"
                class="text-primary mb-25 font-weight-bold"
                :href="`/collector/${item.id}`"
                @click="onRowSelected(item.id)"
              >
                {{ item.GUID }}
              </a>
            </div>
          </template>
          <template #cell(email)="props">
            <a
              v-if="props.item.client.EMAIL"
              :href="'mailto:' + props.item.client.EMAIL"
              class="ml-50 text-right"
            >{{ props.item.client.EMAIL }}</a>
          </template>
          <template #cell(is_robot)="props">
            {{ props.item.is_robot ? 'Автоинформатор' : 'Оператор' }}
          </template>
          <template #cell(companies)="props">
            <div
              v-for="(val, index2) in props.item.companies"
              :key="index2"
            >
              {{ val }}
            </div>
          </template>
        </b-table>
      </b-card>

      <b-card>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap mr-1">Показать по</span>
            <b-form-select
              v-model="params.page_size"
              :options="['5', '10', '20']"
              class="mx-1"
              @change="refresh"
            />
            <span class="ml-1 text-nowrap"> строк( {{ callHistory.count }} )</span>
          </div>
          <div>
            <b-pagination
              v-model="params.page"
              :total-rows="callHistory.count"
              :per-page="params.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BFormSelect,
  BOverlay,
  BPagination,
  BTable,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import empty from '@components/empty.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import VSelect from 'vue-select'
// import test from '@/modules/callHistory/components/test.vue'

export default {
  components: {
    BOverlay,
    empty,
    BFormSelect,
    BTable,
    BCard,
    BPagination,
    VSelect,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    // test,
  },
  data() {
    return {
      fields: [
        {
          label: 'ФИО',
          key: 'client.FULL_NAME',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
          editable: true,
        },
        {
          label: 'Портфель',
          key: 'companies',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
          editable: true,
        },
        {
          label: 'Номер договора',
          key: 'debts',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
          editable: true,
        },
        {
          label: 'ИНН',
          key: 'client.INN',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
          editable: true,
        },
        {
          label: 'Номера телефонов',
          key: 'phone',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Email',
          key: 'email',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Статус',
          key: 'status',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Статус звонков',
          key: 'status_call',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Тип звонка',
          key: 'is_robot',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Оператор',
          key: 'user.full_name',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Дата создания',
          key: 'created_date',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
      ],
      status: [],
      status_call: [],
      params: {
        page: 1,
        page_size: 10,
        is_robot: null,
        search: null,
        user: null,
        company: null,
      },
      loader: false,
      exportXls: false,
      time: null,
    }
  },
  computed: {
    ...mapState('callHistory', ['callHistory', 'status2', 'status_call2', 'is_robot']),
    ...mapState('dk', ['users']),
    ...mapState('cases', ['debtCompanyList']),
  },
  mounted() {
    const req = {
      page_size: 1000,
    }

    this.FETCH_USERS({ role_unique_name: 'dk', ...req })
    this.FETCH_SK_DEBT_COMPANY_LIST(req)
    this.refresh()
  },
  methods: {
    ...mapActions('callHistory', ['FETCH_CALL_HISTORY', 'FETCH_CALL_EXCEL']),
    ...mapActions('dk', ['FETCH_CURRENT_ID', 'FETCH_USERS']),
    ...mapMutations('collectorDetail', ['SET_ACTIVE', 'SET_CALL']),
    ...mapActions('cases', ['FETCH_SK_DEBT_COMPANY_LIST']),

    searchConstructor() {
      this.params.page = 1

      clearTimeout(this.time)

      this.time = setTimeout(() => {
        this.refresh()
      }, 400)
    },

    filteredCall() {
      this.params.page = 1

      const status_call = this.status_call.map(el => el.value).join(',')
      const status = this.status.map(el => el.value).join(',')

      this.FETCH_CALL_HISTORY({
        status_call,
        status,
        ...this.params,
      })
    },

    filtered() {
      this.params.page = 1

      const status_call = this.status_call.map(el => el.value).join(',')
      const status = this.status.map(el => el.value).join(',')

      this.FETCH_CALL_HISTORY({
        status_call,
        status,
        ...this.params,
      })
    },

    async exportExcel() {
      this.exportXls = true
      try {
        const status_call = this.status_call.map(el => el.value).join(',')
        const status = this.status.map(el => el.value).join(',')

        const res = await this.FETCH_CALL_EXCEL({
          status_call,
          status,
          ...this.params,
        })
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.xlsx')
        document.body.appendChild(link)
        link.click()
        await this.$_okToast()
      } catch (error) {
        console.error(error, 'An error occurred')
        await this.$_errorToast(error)
      } finally {
        this.exportXls = false
      }
    },

    async refresh() {
      try {
        this.loader = true

        const status_call = this.status_call.map(el => el.value).join(',')
        const status = this.status.map(el => el.value).join(',')

        await this.FETCH_CALL_HISTORY({
          status_call,
          status,
          ...this.params,
        })
      } catch (e) {
        this.loader = false
        await this.$_errorToast(e)
      }
      this.loader = false
    },

    async onRowSelected(id) {
      try {
        console.log(id)
        this.SET_ACTIVE(1)
        this.SET_CALL(false)

        // await this.$router.push({
        //   name: 'collector-id',
        //   params: {
        //     id,
        //   },
        // })
      } catch (e) {
        this.loader = false
        await this.$_errorToast(e)
      }
    },

    changePage(page) {
      this.params.page = page
      this.refresh()
    },
  },
}
</script>
